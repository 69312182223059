var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('shop.article.property.unit', 100),"items":_vm.propertyUnits,"headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultPropertyUnitItem},on:{"crudDialogSave":function (updatedItems, crudDialogMode, crudItem, crudItemIndex) { return _vm.$emit(
                'crudDialogSave',
                updatedItems,
                crudDialogMode,
                crudItem,
                crudItemIndex
            ); }},scopedSlots:_vm._u([{key:"item.names",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.names .filter( function (langCodeName) { return langCodeName.langcode === _vm.$root.$i18n.locale; } ) .map(function (langCodeName) { return langCodeName.name; }) .join(", "))+" ")]}},{key:"crud-form",fn:function(ref){
            var item = ref.item;
return [_c('AdminLangCodeNameTable',{attrs:{"editable":"","langCodeNames":item.names},on:{"crudDialogSave":function (updatedLangCodeNames) { return (item.names = updatedLangCodeNames); }}}),_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.idAdditive')},model:{value:(item.idAdditive),callback:function ($$v) {_vm.$set(item, "idAdditive", $$v)},expression:"item.idAdditive"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }