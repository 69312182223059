var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('language.name', 100),"items":_vm.langCodeNames.map(function (item, key) { return (Object.assign({}, item, {key: key})); }),"item-key":"key","headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultLangCodeNameItem},on:{"crudDialogSave":function (updatedItems, crudDialogMode, crudItem, crudItemIndex) { return _vm.$emit(
                'crudDialogSave',
                updatedItems,
                crudDialogMode,
                crudItem,
                crudItemIndex
            ); }},scopedSlots:_vm._u([{key:"crud-form",fn:function(ref){
            var item = ref.item;
return [_c('v-text-field',{attrs:{"label":_vm.$tc('language.name'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('v-select',{attrs:{"label":_vm.$t('language.langCode'),"items":_vm.availableLanguages,"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.langcode),callback:function ($$v) {_vm.$set(item, "langcode", $$v)},expression:"item.langcode"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }