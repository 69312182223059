var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('shop.article.property', 100),"items":_vm.properties,"headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultPropertyItem,"crudPermissionSubject":"ArticleProperty","crudURLPrefix":"/admin/article/property"},on:{"crudDialogSave":function (updatedItems) { return (_vm.properties = updatedItems); }},scopedSlots:_vm._u([{key:"item.names",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.names .filter( function (langCodeName) { return langCodeName.langcode === _vm.$root.$i18n.locale; } ) .map(function (langCodeName) { return langCodeName.name; }) .join(", "))+" ")]}},{key:"item.units",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.units .flatMap(function (unit) { return unit.names; }) .filter( function (langCodeName) { return langCodeName.langcode === _vm.$root.$i18n.locale; } ) .map(function (langCodeName) { return langCodeName.name; }) .join(", "))+" ")]}},{key:"item.needsValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.needsValue === 1 ? _vm.$t("label.yes") : _vm.$t("label.no"))+" ")]}},{key:"crud-form",fn:function(ref){
var item = ref.item;
return [_c('AdminLangCodeNameTable',{attrs:{"editable":"","langCodeNames":item.names},on:{"crudDialogSave":function (updatedLangCodeNames) { return (item.names = updatedLangCodeNames); }}}),_c('AdminPropertyUnitTable',{attrs:{"editable":"","propertyUnits":item.units},on:{"crudDialogSave":function (updatedUnits) { return (item.units = updatedUnits); }}}),_c('v-checkbox',{attrs:{"label":_vm.$t('shop.article.property.needsValue')},model:{value:(item.needsValue),callback:function ($$v) {_vm.$set(item, "needsValue", $$v)},expression:"item.needsValue"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }