






import { Component, Vue } from "vue-property-decorator";
import AdminPropertyTable from "@/components/admin/property/AdminPropertyTable.vue";

/**
 * AdminPropertyManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminPropertyTable,
    },
})
export default class AdminPropertyManagement extends Vue {}
