
































































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { Property } from "@/types/property";
import { LangCodeName } from "@/types/language";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminLangCodeNameTable from "@/components/admin/language/name/AdminLangCodeNameTable.vue";
import AdminPropertyUnitTable from "@/components/admin/property/unit/AdminPropertyUnitTable.vue";

/**
 * AdminPropertyTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminLangCodeNameTable,
        AdminPropertyUnitTable,
    },
})
export default class AdminPropertyTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private properties: Property[] = [];

    private defaultPropertyItem: Property = {
        id: -1,
        names: [],
        units: [],
        needsValue: false,
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.name").toString(),
                value: "names",
            },
            {
                text: this.$tc("shop.article.property.unit", 100).toString(),
                value: "units",
            },
            {
                text: this.$t("shop.article.property.needsValue").toString(),
                value: "needsValue",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.properties = await this.fetchProperties();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all properties
     *
     * @returns Promise<Property[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchProperties(): Promise<Property[]> {
        const response = (
            await axios.get<APIResponse<Property[]>>("/articles/properties")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
